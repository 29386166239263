<template>
  <div id="app" style="background: orange; padding-bottom: 50px">
    <div class="teambanner">
      <img :src="teamBanner">
    </div>
    <div v-if="couponListId" class="couponDetail">
      <div>
        <div class="left">
          <div>{{ coupoList.couponName }}</div>
          <div class="price">
            <span>￥</span><span>{{ coupoList.usedMoney }}</span><span>优惠券</span>
          </div>
          <div>有效期:{{ coupoList.usedRemind }}</div>
          <img
            src="https://yuetao-1300766538.cos.ap-beijing.myqcloud.com/yuetao/image/2020-09-25/16/yuelvhuiy2LqLVhxxJ1601022447.png"
            alt=""
          >
          <!-- <img v-else src="../images/coupons/noCAnGet1.png" alt=""> -->
        </div>
        <div class="right">
          <!-- <div>立即领取</div> -->
          <div>已领取</div>
          <!-- <div v-else='noCanGet'>立即使用</div> -->
        </div>
      </div>
    </div>
    <div class="list_box">
      <div
        v-for="(item) in shopList"
        :key="item.goodId"
        class="list_item"
        @click="goShopdetail(item)"
      >
        <div class="itemimg"><img :src="item.goodCover" alt=""></div>
        <div>
          <p class="item_goodname">{{ item.goodName }}</p>
          <p class="item_pricebox">
            <span class="item_pricevip">¥{{ item.goodVipPrice }}</span>
            <span class="item_price">¥{{ item.goodPrice }}</span>
          </p>
        </div>
      </div>
    </div>
    <!--提示-->
    <div id="toast" class="toast" />
  </div>
</template>

<script>
import Vue from 'vue'
import { Toast } from 'vant'

Vue.use(Toast)

import {
  ajaxNewActivityList,
  ajaxGetCouponList
} from '@/services/couponPage'

export default {
  data() {
    return {
      shopList: '',
      teamBanner: '',
      coupoList: '',
      couponListId: ''
    }
  },
  mounted() {
    const _oid = this.getQueryVariable('oid')
    this.getList(_oid)
  },
  methods: {
    getListCoupon(id) {
      const _self = this
      const _data = {
        uid: localStorage.getItem('uid'),
        couponActivityId: id
      }
      ajaxGetCouponList(_data).then((res) => {
        if (Number(res.code) === 200) {
          _self.coupoList = res.data.list[0]
        } else {
          // Toast(res.msg);
        }
      })
    },
    getList(id) {
      const _self = this
      const _data = {
        id: id,
        categoryId: 0,
        page: 1,
        pageSize: 10
      }

      ajaxNewActivityList(_data).then((res) => {
        if (Number(res.code) === 200) {
          _self.couponListId = res.couponListId
          _self.shopList = res.data[0].goodsInfo
          _self.teamBanner = res.data[0].teamBanner
          _self.getListCoupon(res.couponListId)
        } else {
          Toast(res.msg)
        }
      })
    },
    goShopdetail(e) {
      const _self = this
      this.$router.push('/goodsDetailZY?goodsId=' + e.goodId + '&skuid=' + e.skuId + '&type=ordinary&codeNumber=' +
        _self.getQueryVariable('codeNumber') +
        '&room_id=' +
        _self.getQueryVariable('room_id'))

      // window.location.href =
      //   "https://single.yuetao.group/good-detail.html?product_id=" +
      //   e.goodId +
      //   "&product_sku_id=" +
      //   e.skuId +
      //   "&type=ordinary&codeNumber=" +
      //   _self.getQueryVariable("codeNumber") +
      //   "&room_id=" +
      //   _self.getQueryVariable("room_id");
    },
    getQueryVariable: function(variable) {
      var query = window.location.search.substring(1)
      var vars = query.split('&')
      for (var i = 0; i < vars.length; i++) {
        var pair = vars[i].split('=')
        if (pair[0] === variable) {
          return pair[1]
        }
      }
      return false
    }
  }
}
</script>

<style scoped>
.teambanner {
  width: 100%;
  /* height: 290px; */
}

.teambanner img {
  width: 100%;
  height: 100%;
  display: block;
}

.list_box {
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
}

.list_item {
  text-align: left;
  width: 45%;
  height: 230px;
  background: #fff;
  border-radius: 5px;
  margin-top: 10px;
  padding: 5px;
}

.itemimg {
  width: 100%;
  height: 150px;
}

.itemimg img {
  width: 100%;
  height: 100%;
  display: block;
}

.item_price {
  font-size: 12px;
  text-decoration: line-through;
  color: gray;
}

.item_pricevip {
  font-size: 15px;
  color: red;
  margin-right: .4rem;
}

.item_goodname {
  font-size: 15px;
  width: 100%;
  /*text-overflow: -o-ellipsis-lastline;*/
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
  font-weight: bold;
  line-height: 1.2;
}

.item_pricebox {
  line-height: 35px;
  padding-left: 10px;
}
</style>
